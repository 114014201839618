// LoginForm.scss
@use "../global" as global;
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
  background: global.$color1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  

  div {
    display: grid;
    align-items: center;

    label {
      margin-bottom: 5px;
      width: 90px;
      justify-self: flex-start;
    }

    input {
      margin-bottom: 10px;
      padding: 8px;
      width: 250px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

   
    button {
      padding: 10px 20px;
      width: 250px;
      background-color: global.$default-button-color;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: global.$default-button-hover-color;
      }
    }
  }
}
.login-input-container{
  display: flex;
  flex-direction: column;
  margin-left: 30%;
  margin-right: 30%;
}

.login-labels{
  text-align: left;
}
.login-input-field{
  display: flex;
  text-align: start;
  padding-left: 20px;
  font-size: 0.9rem;
  color:rgb(19, 13, 37);
  height: 35px;
  border: 1px solid rgba(204, 204, 204, 0.315);
  border-radius: 7px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.login-container{
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  width: 100%;
}
.login-error-message{
  margin-top: 10px;
  color: global.$login-error-message;
}
.login-submit-btn{

  width: 250px;
  height: 35px;
  font-size: 0.9rem;
  background-color: global.$default-button-color;
  color: white;
  border: none;
  border-radius: 7px;
  align-content: center;
  text-align: center;
  align-self: center;
  cursor: pointer;

  &:hover {
    background-color: global.$default-button-hover-color;
  }
    
}
