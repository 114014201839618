.popupRemoveFolder-select-column-container {
    display: flex;
    width: 100%;
    
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.popupRemoveFolder-headline{
    color: #262B4D;
    font-weight: 100;
    margin-bottom: 0px;
    font-size: 1.8rem;
}
.popupRemoveFolder-subtext{
 
    font-size: 1rem;
    font-weight: 500;
}
.popupRemoveFolder-submit-btn {

padding: 2px 30px;
border: none;
cursor: pointer;
text-align: center;
border-radius: 5px;
outline: none;
text-decoration: none;

line-height: 30px;
margin-bottom: 20px;
height: 34px; 
box-sizing: border-box;
}
.popupRemoveFolder-submit-btn-delete {

    padding: 2px 30px;
    border: none;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    outline: none;
    text-decoration: none;
    background-color:#e02884;
    
    line-height: 30px;
    margin-bottom: 20px;
    height: 34px; 
    box-sizing: border-box;
    }
    .popupRemoveFolder-submit-btn-edit {

        padding: 2px 30px;
        border: none;
        cursor: pointer;
        text-align: center;
        border-radius: 5px;
        outline: none;
        text-decoration: none;
        background-color:#1ED6B2;
        
        line-height: 30px;
        margin-bottom: 20px;
        height: 34px; 
        box-sizing: border-box;
        }
        .popupRemoveFolder-submit-btn-delete:hover
        {
            background-color: #c22875;
        }
        .popupRemoveFolder-submit-btn-edit:hover
        {
            background-color: #00B9BF;
        }
    

.popupRemoveFolder-select {
    height: 45px;
    width: 100%;
    background-color: white;
    border: 1px solid #00000021;
    box-shadow: 0 0 0 rgb(0 0 0 / 10%);
    padding: 0 10px;
    border-radius: 7px;
    margin-top: 20px;
    margin-right: 0px;
}

.popupRemoveFolder-select {
    height: 45px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    &::placeholder {
        color: grey;
    }
}
