// LoginPage.scss
@use "../global" as global;
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: global.$login-page-background-color;
}
.LoginPage-inner-wrapper {
  padding-left: 40px;
  text-align: center;
  img {
    margin-bottom: 1rem; // Minskad marginal under logotypen
    width: 30%;
  }

  h1 {
    color: global.$color1; // Matchar rubrikens färg med sidans tema
    margin-bottom: 1rem; // Minskad marginal under rubriken
    font-size: 1.4rem;
  }
}
