// BuildingPage.scss
@use "../../global" as global;
.building-page-layout {
  display: flex;
  align-items: flex-start;
  background-color: global.$background-color;

  min-height: 100vh;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 75px;
  margin-bottom: 75px;

  .flex-form {
    display: flex; // Skrev över för att bli av med display:block...
    flex-wrap: wrap;
    column-gap: 10px;
  }

  .flex100 {
    flex-basis: 100%;
    flex-grow: 1;
  }
  .flex50 {
    flex-basis: 35%;
    flex-grow: 1;
  }
  .flex30 {
    flex-basis: 25%;
    flex-grow: 1;
  }
  .input-group {
    display: flex;
    flex-wrap: wrap;
  }
  .input-group .flex30,
  .input-group .flex50,
  .input-group .flex100 {
    //width: 100%; /* Säkerställ att input tar upp hela bredden av sin grupp */
  }
  .error-message {
    color: rgb(0, 255, 0);
    font-size: 14px;
    margin-top: 5px; /* Mindre mellanrum efter input */
  }

  .success-message {
    color: green;
    font-size: 14px;
    margin-top: 5px; /* Mindre mellanrum efter input */
  }
  h1 {
    color: #333;
    margin-bottom: 20px;
    font-size: 1.3rem;
    font-family: global.$special-text-font;
  }

  .card-link {
    text-decoration: none;
    color: black;
    transition: color 0.3s ease;

    &:hover {
      color: rgb(29, 63, 33);
    }

    &:focus,
    &:active {
      color: #333;
      outline: none;
    }
  }
  .viewAllLink {
    text-decoration: none;

    color: global.$link-color;
    font-size: 1rem;
    font-weight: 500;
  }
  .viewAllLink:hover {
    font-weight: 600;
    color: global.$link-hover-color;
  }

  .building-content-left {
    display: flex;

    flex-direction: column;

    width: 70%;

    > * {
      // Direkta barn till .content
      flex-grow: 0; // Förhindrar att element växer utöver deras bas
      flex-shrink: 0; // Förhindrar att element krymper
    }
  }
  .building-content-right {
    display: flex;

    flex-direction: column;
    margin-top: 60px;
    width: 30%;
    margin-top: 60px;
    margin-left: 50px;
    > * {
      // Direkta barn till .content
      flex-grow: 0; // Förhindrar att element växer utöver deras bas
      flex-shrink: 0; // Förhindrar att element krymper
    }

    .building-content-right-latest-document {
      margin-top: 20px;
    }
  }
}
.building-content-right-latest-news {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; /* Valfri margin mellan sektionerna */
}
.building-content-right-latest-news h2 {
  margin: 0;
  text-align: left;
}
.view-all-folders {
  text-decoration: none;
  font-size: 0.8; /* Justera efter behov */
  color: global.$link-color;

  &:hover {
    text-decoration: underline;
    color: global.$link-hover-color
  }
}


/*StartPage Endast stilar som avviket från Template får skrivas här..*/

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 44px;
  justify-content: center;
  padding-top: 40px;
}

/*Endast utfyllnad för ej skapade kort...*/
.empty-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  flex-grow: 2;
  gap: 10px;
}
@media (max-width: 1139px) {
  .empty-card {
    display: none;
  }
}

// Responsivitet
@media (max-width: 768px) {
  .page-layout {
    padding: 10px;
    .content {
      padding: 10px;
      input,
      select {
        height: 40px;
        flex: 1 1 100%;
      }
    }
  }
}