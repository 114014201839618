// Template.scss
@use "../global" as global;
.page-layout {
  display: flex;
  align-items: flex-start;
  background-color: global.$background-color;
  padding-top: 80px;
  padding-bottom: 80px;
  min-height: 100vh;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 75px;
  margin-bottom: 75px;

  .flex-form {
    display: flex; // Skrev över för att bli av med display:block...
    flex-wrap: wrap;
    column-gap: 10px;
  }

  .flex100 {
    flex-basis: 100%;
    flex-grow: 1;
  }
  .flex50 {
    flex-basis: 35%;
    flex-grow: 1;
  }
  .flex30 {
    flex-basis: 25%;
    flex-grow: 1;
  }
  .input-group {
    display: flex;
    flex-wrap: wrap;
  }
  .input-group .flex30,
  .input-group .flex50,
  .input-group .flex100 {
    //width: 100%; /* Säkerställ att input tar upp hela bredden av sin grupp */
  }
  .error-message {
    color: global.$error-msg-color;
    font-size: 14px;
    margin-top: 5px; /* Mindre mellanrum efter input */
  }

  .success-message {
    color: global.$success-msg-color;
    font-size: 14px;
    margin-top: 5px; /* Mindre mellanrum efter input */
  }
  h1 {
    color: #262b4d;
    margin-bottom: 20px;
  }

  .card-link {
    text-decoration: none;
    color: global.$link-color;
    transition: color 0.3s ease;

    &:hover {
      color: global.$link-hover-color;
    }

    &:focus,
    &:active {
      color: #262b4d;
      outline: none;
    }
  }

  .content {
    display: flex;

    flex-direction: column;

    width: 100%;

    > * {
      // Direkta barn till .content
      flex-grow: 0; // Förhindrar att element växer utöver deras bas
      flex-shrink: 0; // Förhindrar att element krymper
    }

    input,
    select {
      height: 45px;
      width: 200px;
      background-color: global.$select-input-color;
      border: 1px solid #00000021;
      box-shadow: 0 0 0 rgb(0 0 0 / 10%);
      padding: 0 10px;
      border-radius: 7px;
      margin-top: 20px;
      margin-right: 0px;
    }

    select {
      height: 45px;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);

      &::placeholder {
        color: global.$select-color;
      }
    }
  }

  .select-label {
    color: #262b4d;
    font-weight: bold;
    margin-bottom: 5px;
  }
}
/* Chrome, Safari, Edge, Opera - remove arrows in number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox - remove arrows in number input */
input[type=number] {
  -moz-appearance: textfield;
}
.template-subsection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}
.template-subsection-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}

.left-section {
  flex: 1;
}

.right-section {
  display: flex;
  align-items: center;
}

.right-section input {
  margin-right: 10px;
  padding: 5px;
}

.right-section button {
  padding: 5px 10px;
}
// Responsivitet
@media (max-width: 768px) {
  .page-layout {
    padding: 10px;
    .content {
      padding: 10px;
      input,
      select {
        height: 40px;
        flex: 1 1 100%;
      }
    }
  }
}
.searchFilterbtn {
}
.dropbtn {
  background-color: global.$button-ok-color;
  color: white;
  border-radius: 7px;
  height: 45px;
  width: 130px;
  text-align: left;
  font-weight: 300;
  margin-left: 10px;
  margin-right: 0;
  //white-space: 10px;
  //padding-top: 10px;
  margin-top: 20px;
  //padding-bottom: 10px;

  font-size: 0.8rem;
  border: none;
  cursor: pointer;
}
.dropbtn-filtericon {
  margin-right: 20px;
  z-index: 1000;
}
.dropbtn:hover,
.dropbtn:focus {
  background-color: #0054B5;
  color: white;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: global.$button-ok-hover-color;
  color: #f1f1f1;
  border-radius: 3px;
}

.show {
  display: block;
}
