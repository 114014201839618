/* NewsTemplate.css */
@use "../../global" as global;
@use "../../Buttons/default-buttons" as defaultButtons;
.news-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 100px;
  background-color: rgb(255, 255, 255);
  border-radius: 7px;
  margin-bottom: 20px;
  margin-top: 20px; // varje komponent har 20px i förhållande ovan
  padding: 20px;
}

.news-info {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.news-icon,
.news-pic {
  //width: 100px;
  height: 50px;
  //border-radius: 50px;
  margin-right: 10px;
  overflow: visible;
}

.news-details {
  display: flex;
  flex-direction: column;
}
.news-newsHeadline {
  font-size: 1.6rem;
  font-weight: 500;
}
.news-roleArray {
  font-size: 0.9rem;
}
.news-newsUnitArray {
  font-size: 0.9rem;
}
.news-roleArray,
.news-newsHeadline,
.news-newsUnitArray {
  margin-left: 20px;
}

.news-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

button {
  padding: 5px 10px;
}

/*Building Page*/
.news-item-building {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.news-info-building {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.news-roleArray-building {
  display: flex;

  flex-direction: column;
}

.news-pic-building {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.news-dateandplace-building {
  font-size: 0.9rem;
  color: #aaabb2;
  font-weight: 500;
}

.news-details-building {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.news-name-title-building {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 20px;
}
.news-info-content-building {
  display: flex;
  flex-direction: row;
}

.news-newsHeadline-building {
  font-weight: 300;
  color: #76798c;
}

.news-date-building {
  font-size: 12px;
  color: gray;
}

.news-admin-role-building {
  margin-left: auto;
  font-size: 14px;
  color: #007bff;
}

.news-content-building {
  display: flex;
  flex-direction: column;
  margin-left: 49px;
  margin-right: 49px;
}
.news-userName-building {
  font-weight: 500;
}
.news-role-building {
  font-weight: 500;
  color: #898da7;
}

.news-headline-building {
  font-size: 1.6rem;
  font-weight: 500;
  color: #262b4d;
  margin-bottom: 10px;
}
.role-icon-building {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.news-main-pic-building {
  width: 100%;
  border-radius: 5px;
  height: auto;
  margin-bottom: 10px;
}

.news-text-building {
  font-size: 0.9rem;
  color: black;
  font-weight: 100;
}

.news-actions-building {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
