.back-button-wrapper {
  //display: flex;
  //align-items: center;
  display: flex;
  align-items: flex-start;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;

  .back-button-big {
    //margin-right: 16px
  }

}
.back-button-slick,
.back-button {
  display: flex;
  align-items: start;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  color: #585858;
  //font-family: "Avenir", sans-serif;
  padding-left: 0;
}
.back-button-slick:hover,
.back-button:hover {background-color: transparent}