/* global.css */
//@import url('https://path.to/fonts/avenir/font.css');

/* Global variables */
$heading-font: 'EB Garamond', medium;
$special-text-font: 'Gabriola';
$body-text-font: 'Nunito', regular;

$old-font: 'Avenir', sans-serif;

$color1: #ffffff;
$color2: #F2F5FA;
$color3: #1ED6B2;
$color4: #00B9BF;
$color5: #009AC9;
$color6: #0078C8;
$color7: #0054B5;
$color8: #2B3EC1;
$color9: #262B4D;
$color10: #e02884;
$color11: #c22875;

$navbar-color: $color9;
$nav-item-hover-color: $color2;

$link-color: $color8;
$link-hover-color: $color8;

$span-text-color: $color8;

$background-color: $color2;

$login-error-message: $color11;

$building-menu-active: $color7;

$icon-size: 64px;

$login-page-background-color: $color9;

$error-msg-color: $color4;
$success-msg-color: $color3;

$select-color: $color2;
$select-input-color: $color1;

$special-section-color: lightgray;

$button-cancel-color: $color5;
$button-cancel-hover-color: $color4;
$default-button-color: $color8;
$default-button-hover-color: $color7;
$button-ok-color: $default-button-color;
$button-ok-hover-color:$default-button-hover-color;
$button-more-color: $default-button-color;
$button-more-hover-color: $default-button-hover-color;
$button-contact-color: $default-button-color;
$button-contact-hover-color: $default-button-hover-color;
$upload-button-color : $color5;
$upload-button-hover-color: $color4;
$change-button-color : $default-button-color;
$change-button-hover-color: $default-button-hover-color;
$button-download-color: $color5;
$button-download-hover-color: $color4;

//$button-color: $button-ok-color;
//$button-hover-color: $button-ok-hover-color;

/* Global CSS */

body {
  font-family: $body-text-font;
}

h1,
h2 {
  font-family: $heading-font;
  font-weight: normal;
  font-size: 3rem;
}
img {
  font-family: "Avenir", sans-serif;
}
button {
  font-family: $special-text-font;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.3rem;
  font-weight: 400;
}

.hidden {
  display: none
}
.grid {
  display: grid;
}

.full-opacity {
  opacity: 1 !important;
}
.opacity-0 {
  opacity: 0 !important;
  cursor: default;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-100 {
  opacity: 1 !important;
}

.filter-color {
  fill: white;
  filter: invert(14%) sepia(85%) saturate(3180%) hue-rotate(232deg) brightness(106%) contrast(93%);
}

.debug-text {
  opacity: 0.25;
  font-size: small;
  z-index: -1;
}
