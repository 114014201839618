@use "../global" as global;

.popup-confirm-deletion-select-column-container {



}
.popup-confirm-deletion-btns {
  display: flex;
  justify-content: space-evenly;
}
