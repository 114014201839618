/*Endast stilar som avviket från Template får skrivas här..*/
@use "../global" as global;
.profile-page {
  .special-section {
    margin-top: 40px;
    padding: 20px;
    background-color: global.$special-section-color;
    border-radius: 5px;
    h2 {
      color: darkred;
    }
    p {
      color: black;
    }
  }

}
.user-list{
  width: 100%;
}
