/*Endast stilar som avviket från Template får skrivas här..*/
@use "../global" as global;
.account-page {
  .special-section {
    margin-top: 40px;
    padding: 20px;
    background-color: global.$special-section-color;
    border-radius: 5px;
    h2 {
      color: darkred;
    }
    p {
      color: black;
    }
  }
}



.edit-account-header-section {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.edit-account-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 45px;
}

.edit-account-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.edit-account-text h1 {
  margin: 0;
}

.edit-account-text p {
  margin: 0;
}
