/* Specifik selektor för dokumentlistan */
@use "../../global" as global;
.folder-document-row-container {
  display: flex;
  flex-direction: column;
  width: 100%; /* Full bredd */
}

.folder-document-row {
  display: flex;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid #ccc;
  width: 100%; /* Full bredd för varje rad */
}

.folder-document-cell {
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center; /* Centrerar innehållet vertikalt */
}

/* Ställer in flex-basis för ikoncellen utan att använda flex-grow */
.folder-document-icon {
  flex: 0 0 40px; /* Återställer detta till 40px, eller justera efter behov */
  display: flex;
  justify-content: center; /* Centrerar ikonen horisontellt inom cellen */
  align-items: center; /* Centrerar ikonen vertikalt inom cellen */
  height: 50px;
  width: 100%;
  background-color: white;
  border-radius: 4px;
}
.folder-document-icon img {
  //height: 30px; /* Sätter höjd på ikonen */
  width: 30px; /* Sätter bredd på ikonen */
}

/* Ställer in resten av cellerna för att dela resten av utrymmet */
.folder-document-folder,
.folder-document-title,
.-folder-document-action {
  flex: 1; /* Flex-grow är 1, vilket delar utrymmet jämnt */
  font-weight: 500;
  font-size: 1rem;
}

.folder-document-download-btn {
  /* Button styling */
  background-color: global.$button-ok-color;
  color: white;
  padding: 2px 30px;
  border: none;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  outline: none;
  text-decoration: none;
  display: inline-block;
  line-height: 30px;
}

.folder-document-download-btn:hover {
  background-color: global.$button-ok-hover-color;
}
