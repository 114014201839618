.popupEditDocuments-select-column-container {
    display: flex;
    width: 100%;
    
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.popupEditDocuments-headline{
    color: #262B4D;
    font-weight: 100;
    margin-bottom: 0px;
    font-size: 1.8rem;
}
.popupEditDocuments-subtext{
  
    font-size: 0.9rem;
    font-weight: 100;
}
.popupEditDocuments-submit-btn {

padding: 2px 30px;
border: none;
cursor: pointer;
text-align: center;
border-radius: 5px;
outline: none;
text-decoration: none;

line-height: 30px;
margin-bottom: 20px;
height: 34px; 
box-sizing: border-box;
}



.popupEditDocument-select {
    height: 45px;
    width: 100%;
    background-color: white;
    border: 1px solid #00000021;
    box-shadow: 0 0 0 rgb(0 0 0 / 10%);
    padding: 0 10px;
    border-radius: 7px;
    margin-top: 20px;
    margin-right: 0px;
}

.popupEditDocument-select {
    height: 45px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    &::placeholder {
        color: grey;
    }
}

.debug-text-popup-1 {
    font-size: small;
}
.debug-text-popup-2 {
    opacity: 0.75;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}
