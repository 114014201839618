/* Specifik selektor för dokumentlistan */
@use "../../global" as global;
.folder-document-row-container {
    display: flex;
    flex-direction: column;
    width: 100%; /* Full bredd */
  }
  
  .folder-document-row-spacing
  {
      margin-top: 50px;
  }
  .folder-document-row {
    display: flex;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid #ccc;
    width: 100%; /* Full bredd för varje rad */
  }
  
  .folder-document-cell {
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center; /* Centrerar innehållet vertikalt */
    background-color: transparent;

    &:hover {background-color: transparent}
  }
  
  /* Ställer in flex-basis för ikoncellen utan att använda flex-grow */
  .folder-document-icon {
    flex: 0 0 40px; /* Återställer detta till 40px, eller justera efter behov */
    display: flex;
    justify-content: center; /* Centrerar ikonen horisontellt inom cellen */
    align-items: center; /* Centrerar ikonen vertikalt inom cellen */
    height: 50px;
    width: 100%;
    background-color: global.$background-color;
    border-radius: 4px;
  }
  .folder-document-icon img {
    //height: 30px; /* Sätter höjd på ikonen */
    width: 30px; /* Sätter bredd på ikonen */
  }
  
  .folder-document-title{
    flex: 1; /* Flex-grow är 1, vilket delar utrymmet jämnt */
    font-weight: 100;
    font-size: 0.9rem;
    color: rgb(56, 57, 70)
  }
  /* Ställer in resten av cellerna för att dela resten av utrymmet */
  .folder-document-folder,
  .-folder-document-action {
    flex: 1; /* Flex-grow är 1, vilket delar utrymmet jämnt */
    font-weight: 500;
    font-size: 1rem;
    color: rgb(56, 57, 70);
    font-size: small;
  }
  .folder-document-action {
    display: flex;
    gap: 20px;
    align-items: center; 
    height: 100px;
  }
  .folder-document-download-btn {
   
    background-color: global.$default-button-color;
    color: #F2F5FA;
    
    padding: 2px 30px;
    border: none;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    outline: none;
    text-decoration: none;
    display: flex; 
    align-items: center;  
    justify-content: center; 
    line-height: 30px;
    margin-bottom: 20px;
    height: 34px; 
    box-sizing: border-box;
  }
  /*Standard*/
  .folder-document-download-btn.download {
   background-color: global.$color5;
  }
  
  .folder-document-download-btn.edit {
    background-color: global.$color6;
  }
  
  .folder-document-download-btn.delete {
    background-color: global.$color10;
  }

  .folder-document-download-btn.download:hover {
    background-color: global.$color4;
   }
   
   .folder-document-download-btn.edit:hover {
     background-color: global.$color4;
   }
   
   .folder-document-download-btn.delete:hover {
     background-color: global.$color11;
   }

  
  .folder-document-download-btn:hover {
    background-color: global.$default-button-hover-color;
  }
 
  