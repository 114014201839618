// BuildingContactPage.scss
@use "../../global" as global;
.building-contact-page-layout {
  display: flex;
  align-items: flex-start;
  background-color: white;
  min-height: 100vh;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 75px;
  margin-bottom: 75px;
  padding-top: 75px;
  padding-bottom: 75px;

  .flex-form {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
  }
  .flex100 {
    flex-basis: 100%;
    flex-grow: 1;
  }
  .flex50 {
    flex-basis: 35%;
    flex-grow: 1;
  }
  .flex30 {
    flex-basis: 25%;
    flex-grow: 1;
  }
  .input-group {
    display: flex;
    flex-wrap: wrap;
  }

  .input-group .flex30,
  .input-group .flex50,
  .input-group .flex100 {
    //width: 100%; /* Säkerställ att input tar upp hela bredden av sin grupp */
  }
  .error-message {
    color: rgb(0, 255, 0);
    font-size: 14px;
    margin-top: 5px; /* Mindre mellanrum efter input */
  }

  .success-message {
    color: green;
    font-size: 14px;
    margin-top: 5px; /* Mindre mellanrum efter input */
  }
  h1 {
    color: #333;
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .card-link {
    text-decoration: none;
    color: black;
    transition: color 0.3s ease;

    &:hover {
      color: rgb(29, 63, 33);
    }

    &:focus,
    &:active {
      color: #333;
      outline: none;
    }
  }
  .viewAllLink {
    text-decoration: none;

    color: #00b2a1;
    font-size: 1rem;
    font-weight: 500;
  }
  .viewAllLink:hover {
    font-weight: 600;
  }

  .building-contact-content {
    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;
    width: 100%;

    > * {
      // Direkta barn till .content
      flex-grow: 0; // Förhindrar att element växer utöver deras bas
      flex-shrink: 0; // Förhindrar att element krymper
    }
  }
}
.building-contact-page-menu {
  display: flex;
  align-items: flex-start;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;

  .contact-backBtn {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #585858;
    font-family: "Avenir", sans-serif;
  }

  .back-icon {
    width: 7px;
    height: 10px;
    margin-right: 8px;
  }
}
/*StartPage Endast stilar som avviket från Template får skrivas här..*/

.building-contact-management {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f3fffa;
  padding-bottom: 50px;
}
.contact-headline {
  margin-bottom: 200px;
  color: red;
}
.building-contact-staff {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding-bottom: 50px;
}
.card-contact-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;

  //padding-top: 40px;
}
/*
.contact-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  padding: 10px;
  width: 250px;
  height: 100px;
  background-color: white;
  border: 1px solid rgba(204, 204, 204, 0.315);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 2;
}
*/
.contact-card {
  display: flex;
  align-items: center;
  width: 250px;
  height: 100px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.profile-picture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-name-title,
.contact-details {
  margin: 0;
  padding: 0;
}

.contact-name {
  font-weight: bold;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.contact-title {
  font-style: italic;
  font-size: 0.7rem;
  margin-top: -2px;
  margin-bottom: 5px;
  margin-right: 0;
  margin-left: 0;
  padding: 0;
}

.contact-phone {
  margin: 0;
  font-size: 0.7rem;
  text-decoration: none;
}
.contact-email {
  margin: 0;
  font-size: 0.7rem;
  text-decoration: none;
  color: #00ab60;
}
.contact-phone-link {
  text-decoration: none;
  color: inherit;
}
.contact-email-link {
  margin: 0;
  text-decoration: none;
  color: global.$link-color;
}

.contact-phone-link:hover,
.contact-email-link:hover {
  text-decoration: none;
  color: global.$link-hover-color;
}

/*Endast utfyllnad för ej skapade kort...*/
.empty-contact-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  flex-grow: 2;
  gap: 10px;
}
@media (max-width: 1139px) {
  .empty-card {
    display: none;
  }
}

// Responsivitet
@media (max-width: 768px) {
  .page-layout {
    padding: 10px;
    .content {
      padding: 10px;
      input,
      select {
        height: 40px;
        flex: 1 1 100%;
      }
    }
  }
}
