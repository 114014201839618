@use "../global" as global;
.file-upload-progress-bar {
  margin-top: 1vh;
  width: 100%;
  appearance: none;
  height: 1vh;
  border-radius: 100px;

  /* Chrome and Safari */
  &::-webkit-progress-bar {
    background: transparent;
    border-radius: 100px;
  }

  &::-webkit-progress-value {
    background-color: global.$default-button-color;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
  }

  /* Firefox */
  &::-moz-progress-bar {
     background-color: global.$default-button-color;
     border-top-left-radius: 100px;
     border-bottom-left-radius: 100px;
     transition: padding-bottom 1s;
     padding-bottom: var(--value);
     transform-origin: 0 0;
     transform: rotate(-90deg) translateX(-15px);
     padding-left: 15px;
   }

  &::-webkit-progress-value {
    transition: width 0.8s;
  }
}

section.debug-checkmarks {
  flex-grow: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
