// Navbar.scss
@use "../global" as global;
.navbar {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: global.$navbar-color;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  z-index: 10;
  padding-top: 7px;
  padding-bottom: 7px;
}

.wrapper {
  padding-right: 40px;
  padding-left: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1440px;
  z-index: 15;
  gap: 20px;
  height: 60px;
}

.navbar-logo img {
  height: 50px;
}

.nav-items {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;

  a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-weight: 500;

    &:hover {
      color: global.$nav-item-hover-color;
    }
  }
  .pfp-wrapper {
  //  border-radius: 50%;
  //  margin-right: 10px;
  //  overflow: hidden;
  //  max-width: 30px;
  //  max-height: 30px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
}

.hamburger {
  display: none;
  border: none;
  background: none;
  font-size: 30px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .nav-items {
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: global.$color1;

    width: 100%;
    transform: translateY(-100%);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    &.open {
      transform: translateY(0);
    }
  }
}
