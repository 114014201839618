/* UnitTemplate.css */
@use "../../global" as global;
@use "../../Buttons/default-buttons" as defaultButtons;
.unit-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 100px;
  background-color: rgb(255, 255, 255);
  border-radius: 7px;
  margin-bottom: 20px;
  margin-top: 20px; // varje komponent har 20px i förhållande ovan
  padding: 20px;
}

.unit-info {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.unit-pic {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-right: 10px;
}

.unit-details {
  display: flex;
  flex-direction: column;
}
.unit-name {
  font-size: 1.6rem;
  font-weight: 500;
}
.unit-phoneNr {
  font-size: 0.9rem;
}
.unit-email {
  font-size: 0.9rem;
}
.unit-phoneNr,
.unit-name,
.unit-email {
  margin-left: 20px;
}

.unit-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}
.changeBtn,
.deleteBtn,
.moreBtn,
button {
  padding: 5px 10px;
}