// BuildingHeroImage.scss
@use "../../global" as global;
.hero-image {
  width: 100%;
  height: 70vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: global.$background-color;

  .hero-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    background-blend-mode: multiply;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    align-items: center;
  }
  .hero-container {
    width: 1140px;
  }

  .hero-title {
    font-size: 3rem;
    line-height: 1.5em;
    color: white;
    margin: 0;
    align-items: center;
    justify-content: flex-end;
    font-weight: 500;
  }

  .hero-description {
    font-family: global.$special-text-font;
    font-size: 1.3rem;
    line-height: 1.5em;
    color: white;
    margin-top: 20px;
  }
}