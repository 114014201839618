/*Kort*/
/*
.document-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
  width: 1140px;
}

.document-list > div {
  flex: 1 1 24%;
  max-width: 24%;
  box-sizing: border-box;
}
.document-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  background-color: white;
  border: 1px solid rgba(204, 204, 204, 0.315);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 2;
}

.document-card-icon {
  font-size: 48px;
}

.document-card-title {
  font-size: 0.8rem;
  font-weight: 100;
  text-align: center;
}
.document-file-title {
  font-weight: 900;
  color: #262b4d;
}
.document-download-btn {
  background-color: #00b2a1;
  color: white;
  height: 30px;
  padding: 0 30px;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s;
  margin-top: 20px;
  text-decoration: none;
  display: inline-block;
}

.document-download-btn:hover,
.document-download-btn:focus {
  background-color: #02c0ad;
  text-decoration: none;
}
*/

/*Lista*/

.document-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.document-card {
  display: flex;
  align-items: center;
  gap: 20px;
}
/* Ta bort margin-bottom för sista elementet */
.document-card:last-child {
  margin-bottom: 0;
}
.document-card-icon {
  min-width: 28px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.document-card-icon img {
  //width: 28px;
  height: 24.2px;

  //filter: grayscale(30%);
}

.document-list-card-title {
  font-size: 0.9rem;
  color: #262b4d;
}

.document-list-file-title {
  font-weight: bold;
}
