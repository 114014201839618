/* AccountTemplate.css */
@use "../../Buttons/default-buttons" as defaultButtons;
.user-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 100px;
  background-color: rgb(255, 255, 255);
  border-radius: 7px;
  margin-bottom: 20px;
  margin-top: 20px; // varje komponent har 20px i förhållande ovan
  padding: 20px;
}

.user-info {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-right: 10px;
}

.user-details {
  display: flex;
  flex-direction: column;
}
.user-name {
  font-size: 1.6rem;
  font-weight: 500;
}
.user-role {
  font-size: 0.9rem;
}
.user-note {
  font-size: 0.9rem;
}
.user-role,
.user-name,
.user-note {
  margin-left: 20px;
}

.user-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

button {
  padding: 5px 10px;
}
