@use "../global" as global;

.changeBtn,
.deleteBtn,
.moreBtn,
.downloadBtn,
.cancelBtn,
button {
  background-color: global.$default-button-color;
  color: white;
  height: 30px;
  padding: 0 30px;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s;
  margin-top: 20px;
  min-width: 90px;
}
button:hover{
  background-color: global.$default-button-hover-color;
}
.deleteBtn {
  background-color: global.$color10;
  &:hover {
    background-color: global.$color11;
  }
}
.downloadBtn-building {
  background-color: global.$button-download-color;
  color: white;
  border: none;
  //padding: 10px 20px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  cursor: pointer;
  display: grid;
  align-items: center;

  :hover {
    background-color: global.$button-download-hover-color;
  }
}


.formBtn {
  background-color: global.$button-ok-color;
  color: #f2f5fa;
  height: 45px;
  padding: 0 80px;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s;
  margin-top: 20px; // Ska alltid vara samma som select
}

.formBtn:hover {
  background-color: global.$button-ok-hover-color;
}

label.formBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-button {
  background-color: global.$upload-button-color;

  &:hover {
    background-color: global.$upload-button-hover-color;
  }
}


.changeBtn {
  background-color: global.$change-button-color;

  &:hover {
    background-color: global.$change-button-hover-color;
  }
}

.downloadBtn {
  background-color: global.$button-download-color;

  &:hover {
    background-color: global.$button-download-hover-color;
  }
}
