.developer-menu {
  display: flex;
}
.dev-btn-default {
  padding: 10px 20px;
  margin: 5px;
  background-color: rgba(0, 0, 0, 0.18);
  color: rgba(255, 255, 255, 0.35);
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: help;

  &:hover {
    background-color: rgba(0, 0, 0, 0.44);
    color: #c4c4c4;
  }
}
.dev-btn-default.red-btn-state {
  background-color: red;
  color: white;
}
.dev-btn-default.dev-btn-long {
  width: 10vw;
  text-align: center;
}
.flex30 {
  flex-basis: 25%;
  flex-grow: 1;
}