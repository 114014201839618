.folder-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
  width: 1140px;
}
.folder-link {
  color: #00b2a1;
  text-decoration: none;
  font-weight: 500;
}

.folder-list > div {
  flex: 1 1 20%;
  max-width: 24%;
  box-sizing: border-box;
}
.folder-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  background-color: white;
  border: 1px solid rgba(204, 204, 204, 0.315);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 2;
  gap: 10px;
}

.folder-card-icon {
  font-size: 48px;
}

.folder-card-title {
  font-size: 1.1rem;
  text-align: center;
}

/*Lista*/

.folder-list-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.folder-card-list {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
/* Ta bort margin-bottom för sista elementet */
.folder-card-list:last-child {
  margin-bottom: 0;
}

.folder-card-icon-list img {
  width: 30.5px;
  height: 24.2px;

  filter: grayscale(30%);
}

.folder-list-card-title-list {
  font-size: 0.9rem;
  color: #262b4d;
}

.folder-list-file-title-list {
  font-weight: bold;
}
