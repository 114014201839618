@use "../global" as global;
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  background-color: white;
  border: 1px solid rgba(204, 204, 204, 0.315);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 2;
  gap: 10px;
}

.card-icon {
  font-size: 48px; /* Justera storleken på ikonen efter behov */
  display: grid;
  justify-content: center;
  margin-bottom: 15px;

  img {
    max-width: global.$icon-size;
    max-height: global.$icon-size;
  }
}

.card-title {
  font-size: 16px;
  text-align: center;
}
