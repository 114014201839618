// BuildingsMenu.scss
@use "../global" as global;

.bold-text {

  color: global.$building-menu-active !important;

}
.buildingMenyLayout {
  display: flex;
  align-items: flex-start;
  background-color: global.$background-color;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}
.building-menu-separator {
  margin: 0 8px;
}
.builing-menu-separator {
  margin: 0 10px;
  white-space: pre;
  color: #6b6e87;
}
.building-menu-item{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

}

.buildings-menu {
  list-style: none;
  display: flex;
  width: 1140px;
  /* align-items: flex-start; */
  padding: 0;
  margin: 10px 0;

  li {
    color: red;
    font-size: 0.9rem;

    &:last-child {
      margin-right: 0;
    }

    a {
      text-decoration: none;
      color: #6b6e87;
      font-weight: 500;
      transition: color 0.3s ease;
      margin: 0 8px;

      &:hover {
        color: #555;
      }

      &:last-child:after {
        content: none;
      }
    }
  }
}
.building-contactbtn {
  margin-top: 10px;
  //padding-top: 12px;
  //padding-bottom: 12px;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  min-width: 180px;
  background-color: global.$button-contact-color;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
}
.building-menu-link-back {

}