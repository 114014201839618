/*StartPage Endast stilar som avviket från Template får skrivas här..*/

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 44px;
  justify-content: flex-start;
  padding-top: 40px;
}

/*Endast utfyllnad för ej skapade kort...*/
.empty-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  flex-grow: 2;
  gap: 10px;
}
@media (max-width: 1139px) {
  .empty-card {
    display: none;
  }
}
